<template>
  <section
    v-if="page && page.content.length > 0 && page.totalElements > page.size"
    class="pagination-mobile d-flex d-md-none"
  >
    <b-pagination
      class="w-100 mb-0"
      align="fill"
      :per-page="page.size"
      :total-rows="page.totalElements"
      :value="page.number + 1"
      @input="pageChanged"
    ></b-pagination>
  </section>
</template>

<script>
export default {
  name: "MobilePaginationBar",
  /**
   * page = objet Page en cours d'affichage
   */
  props: ["page"],
  methods: {
    pageSizeChanged(pageSize) {
      this.$emit("perPageChanged", pageSize);
    },
    pageChanged(numPage) {
      this.$emit("pageChanged", numPage);
    },
  },
};
</script>
