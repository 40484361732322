<template>
  <b-container
    v-if="page && page.content.length > 0"
    class="pagination-desktop"
    fluid
  >
    <b-row align-v="center" align-h="between">
      <!-- Informations de page -->
      <b-col cols="auto" class="d-print-none">
        <b-row align-v="center">
          <b-col cols="auto">Lignes par page</b-col>
          <b-col cols="auto" class="border-right pl-0">
            <b-form-select
              :value="page.size"
              @input="pageSizeChanged"
              @change="pageSizeChanged"
            >
              <b-select-option :value="10">10</b-select-option>
              <b-select-option :value="20">20</b-select-option>
              <b-select-option :value="50">50</b-select-option>
              <b-select-option :value="100">100</b-select-option>
              <b-select-option :value="10000">Tout</b-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="auto">
            {{ page.number * page.size + 1 }} -
            {{ Math.min((page.number + 1) * page.size, page.totalElements) }}
            sur {{ page.totalElements }}
          </b-col>
        </b-row>
      </b-col>
      <!-- Espace pour contenu personnalisé -->
      <b-col class="text-center">
        <slot></slot>
      </b-col>
      <!-- Boutons de navigation -->
      <b-col class="text-right d-print-none" cols="auto">
        <b-row align-v="center" align-h="end">
          <template v-if="page.totalPages <= 10">
            <b-col cols="auto">
              <b-form-select
                :value="page.number + 1"
                @input="pageChanged"
                @change="pageChanged"
              >
                <b-select-option
                  v-for="item in page.totalPages"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </b-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="auto" class="pl-0">
              sur {{ page.totalPages }} pages
            </b-col>
          </template>
          <b-col v-else cols="auto">
            {{ page.number + 1 }} sur {{ page.totalPages }} page(s)
          </b-col>
          <b-col
            cols="auto"
            class="border-left"
            role="button"
            @click="previous"
          >
            <b-icon-caret-left-fill></b-icon-caret-left-fill>
          </b-col>
          <b-col cols="auto" role="button" @click="next">
            <b-icon-caret-right-fill></b-icon-caret-right-fill>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "DesktopPaginationBar",
  /**
   * label = libellé à utiliser pour désigner le contenu ("ligne(s)" par défaut)
   * page = objet Page en cours d'affichage
   */
  props: ["label", "page"],
  methods: {
    pageSizeChanged(pageSize) {
      this.$emit("perPageChanged", pageSize);
    },
    pageChanged(numPage) {
      this.$emit("pageChanged", numPage);
    },
    first() {
      this.$emit("pageChanged", 1);
    },
    previous() {
      if (this.page.number > 0) {
        this.$emit("pageChanged", this.page.number);
      }
    },
    next() {
      if (this.page.number < this.page.totalPages - 1) {
        this.$emit("pageChanged", this.page.number + 2);
      }
    },
    last() {
      this.$emit("pageChanged", this.page.totalPages);
    },
  },
};
</script>
