<template>
  <div class="bubble-container">
    <div class="bubble-background"></div>
    <div class="bubble-content text-center">
      <slot>Bulle vide</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BubbleComponent",
};
</script>
